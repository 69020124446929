import {Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Popconfirm, Row, Select, Table} from "antd";
import Api from "../../services/Api";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import Column from "antd/es/table/Column";
import flexCurrency from "../../utils/FlexCurrency";

const OldBuyingListPage = () => {
    const [form] = Form.useForm();
    const [recipe, setRecipe] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        values = {...values, createdDate: dayjs(values.createdDate).format("YYYY-MM-DD")}
        Api.post("/api/Buys/OldBuy",values).then(res => {
            message.success("Satış başarıyla kaydedildi").then(r => {})
            getData()
        }).catch(err => {
            message.error(err.response?.data?.detail)
        })
    }

    const onFinisFailed = () => {
        message.error("Lütfen tüm alanları doldurun").then(r => {})
    }

    const getData = () => {
        setLoading(true)
        const query = {
            "sort": [
                {
                    "field": "createdDate",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "CreatedDate.Date",
                "operator": "lt",
                "value": "2024-11-01",
                "logic": "and"
            }
        }

        Api.post("/api/Buys/GetList/ByDynamic?PageIndex=0&PageSize=10000", query).then(res => {
            res.data.items.map((item) => {
                let total = 0;
                let detail= "";
                item.buyDetails.map((buy) => {
                    total += buy.recipeRowTotalPrice
                    detail += buy.recipeName + " " + buy.count + " Kişi, "
                })
                item.priceTotal = parseInt(total.toFixed(2))
                item.detail = detail
            })

            setData(res.data.items)
            setLoading(false)
        }).catch(err => {
            message.error(err.response?.data?.detail)
            setLoading(false)
        })
    }

    const getRecipe = () => {
        Api.get("api/Recipes?PageIndex=0&PageSize=100").then(res => {
            setRecipe(res.data.items)
        }).catch(err => {
            message.error(err.response?.data?.detail)
        })
    }

    const deleteBuy = (id) => {
        Api.delete(`/api/Buys/${id}`).then(res => {
            message.success("Satış iptal edildi")
            getData()
        }).catch(err => {
            message.error(err.response?.data?.detail)
        })
    }

    useEffect(() => {
        getRecipe()
        getData()
    }, []);

    return(
        <div className={"p-1"}>
            <Row gutter={[10,10]}>
                <Col span={24}>
                    <Card title={"Manuel Satış Girişi"} size={"small"} loading={loading}>
                        <Form
                            form={form}
                            onFinish={onFinish}
                            onFinishFailed={onFinisFailed}
                            layout={"inline"}
                            labelAlign={"left"}
                            labelCol={{
                                span: 10,
                            }}
                            colon={false}
                        >
                            <Col span={24}>
                                <Row gutter={[10,10]}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={"Satış Tarihi"}  name={"createdDate"} rules={[{ required: true },]}>
                                            <DatePicker  class={'w-100'}  format={"DD.MM.YYYY"}></DatePicker>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label={"Satış Tipi"}  name={"buyType"} rules={[{ required: true },]}>
                                            <Select className={"w-100"} placeholder={"Satış Tipi Seçin"}>
                                                <Select.Option value={"NAKİT"}>NAKİT</Select.Option>
                                                <Select.Option value={"KREDİ KARTI"}>KREDİ KARTI</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label={"Tarife"}  name={"recipeCode"} rules={[{ required: true },]}>
                                            <Select placeholder={"Satış Tipi Seçin"}>
                                                {recipe.map((item) => (
                                                    <Select.Option value={item.name}>{item.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label={"Adet"} name={"count"} rules={[{ required: true },]}>
                                            <InputNumber placeholder={"0"} className={"w-100"}></InputNumber>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label={"Toplam Tutar"} name={"recipeRowTotalPrice"} rules={[{ required: true },]}>
                                            <InputNumber
                                                placeholder={"0"}
                                                className={"w-100 font-color-green font-weight-bold"}
                                                step={0.01}
                                                precision={2}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className={"text-align-center"}>
                                        <Form.Item
                                            label={"-"}>
                                            <Button type={"primary"} htmlType="submit" className={"w-30"}>Satışı Kaydet</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                        </Form>

                    </Card>
                </Col>

                <Col span={24}>
                    <Card title={"Satışlar"} size={"small"}>
                        <Table
                            rowKey={"id"}
                            key={"id"}
                            dataSource={data}
                            size={"small"}
                            pagination={false}
                            loading={loading}
                        >

                            <Column width={50} dataIndex={"id"} render={(_,record) => {
                                return (
                                    <Popconfirm
                                        title="Satış İptali"
                                        description="Satışı iptal etmek istediğinize emin misiniz?"
                                        onConfirm={() => deleteBuy(record.id)}
                                        onCancel={() => {}}
                                        okText="Evet, iptal et"
                                        cancelText="Vazgeç"
                                    >
                                        <Button size={"small"} type={"primary"} style={{fontSize:10}} danger={true}>İptal</Button>
                                    </Popconfirm>
                                )
                            }}></Column>
                            <Column width={175} title={"Zaman"} dataIndex={"createdDate"} render={(_,record) => {
                                return dayjs(record.createdDate).format("DD.MM.YYYY HH:ss")
                            }}></Column>
                            <Column width={120} title={"Satış Tipi"}  align={"center"} dataIndex={"buyType"}></Column>
                            <Column width={200} title={"İşlem Yapan"} align={"center"} dataIndex={"buyer"}></Column>
                            <Column render={priceTotal => (flexCurrency(priceTotal) + " TL")} title={"Toplam"} align={"right"} dataIndex={"priceTotal"}></Column>
                            <Column title={"Satış Detay"} align={"left"} dataIndex={"detail"}></Column>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default OldBuyingListPage
