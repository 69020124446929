import {useNavigate,} from "react-router-dom";
import {Button, Dropdown, Input, Select, Space} from "antd";
import {
    DownOutlined,
    HomeOutlined,
    LogoutOutlined, MenuOutlined,
    SettingOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useContext} from "react";
import {SerialPortContext} from "../context/SerialPortContext";
import {faChartColumn, faChartLine, faChartPie, faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PUBLIC_URL} from "../services/Api";


const HeaderLayout = () => {
    const {ports, connectToPort, disconnectPort, data} = useContext(SerialPortContext);

    let user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()

    const items = [
        {
            label: 'Çıkış Yapın',
            key: '2',
            icon: <LogoutOutlined />,
            onClick: () => {
                localStorage.removeItem("user");
                navigate("/login")
            }
        },
    ];

    const ayarlar = [

        {
            label: 'Kullanıcılar',
            key: '2',
            icon: <MenuOutlined />,
            onClick: () => navigate("/user")
        },
        {
            label: 'Tarifeler',
            key: '3',
            icon: <MenuOutlined />,
            onClick: () => navigate("/recipe")
        }

    ];

    const raporlar = [
        {
            label: 'Günlük Kasa Raporu',
            key: '1',
            icon: <FontAwesomeIcon icon={faChartPie} />,
            onClick: () => navigate("/rapor/kasa-gunluk-rapor")
        },
        {
            label: 'Tarih Bazlı Kasa Raporu',
            key: '2',
            icon: <FontAwesomeIcon icon={faChartSimple} />,
            onClick: () => navigate("/rapor/kasa-detay-rapor")
        },
        {
            label: 'Kasa Aylık Döküm Raporu',
            key: '3',
            icon: <FontAwesomeIcon icon={faChartColumn} />,
            onClick: () => navigate("/rapor/kasa-aylik-dokum-rapor")
        },
        {
            label: 'Manuel Rapor Giriş',
            key: '4',
            icon: <FontAwesomeIcon icon={faChartLine} />,
            onClick: () => navigate("/oldbuying")
        },
    ];

    const selectedPort = (e) => {
        disconnectPort();

        connectToPort(e);
    }

    return (
        <div className={"header"}>
            <div onClick={() => navigate("/dashboard")} className={"logo"}>
                <img src={PUBLIC_URL + '/assets/images/logo.png'}
                     alt={"Firma Logosu"}></img>
                <div className={"logo-text"}> | RAYTAPARK GEÇİŞ YÖNETİM SİSTEMİ</div>
            </div>
            <div className={"right"}>
                <Space>
                    <Input style={{width:200}} value={data}></Input>

                    <Select onSelect={(e) => selectedPort(e)} style={{minWidth:200}} className={"w-100"} placeholder={"Seri port seçin"}>
                        {ports.map((port) => (
                            <Option key={port.path} value={port.path}>{port.path}</Option>
                        ))}
                    </Select>


                    <Button onClick={() => navigate("/dashboard")}>
                        <Space>
                            <HomeOutlined/> Anasayfa
                        </Space>
                    </Button>

                    <Dropdown menu={{items:raporlar}}>
                        <Button >
                            <Space>
                                <FontAwesomeIcon icon={faChartSimple} /> Raporlar
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>

                    <Dropdown menu={{items:ayarlar}}>
                        <Button >
                            <Space>
                                <SettingOutlined/> Ayarlar
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>

                    <Dropdown menu={{items}}>
                        <Button >
                            <Space>
                                <UserOutlined/><div>{user?.firstName} {user?.lastName}</div>
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Space>
            </div>
        </div>
    )
}

export default HeaderLayout;
